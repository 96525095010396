import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "JSConf JP 2024に協賛し、医療業務システムのReactコンポーネント設計を紹介します",
  "date": "2024-11-11T06:54:08.000Z",
  "slug": "entry/2024/11/11/155408",
  "tags": [],
  "hero": "./thumbnail.png",
  "heroAlt": null
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。医療プラットフォーム本部プロダクト開発室 CLINICS 第二開発グループ所属の髙橋です。`}</p>
    <p>{`メドレーは、2024 年 11 月 23 日に九段坂上 KS ビルにて開催される JSConf JP 2024 にプレミアムスポンサーとして協賛します。`}</p>
    <p>{`今回は、来週末に控えている JSConf JP 2024 で発表するスポンサーワークショップの内容について紹介します。`}</p>
    <h1>{`JSConf JP 2024 とは`}</h1>
    <p>{`JSConf JP は、一般社団法人 Japan Node.js Association が主催する JavaScript の祭典です。`}</p>
    <p>{`国内はもちろん、海外からも Web 開発者を招き、合計 4 トラックで Web 開発に関連する多数のセッションが予定されています。`}</p>
    <p>{`公式サイト： `}<a parentName="p" {...{
        "href": "https://jsconf.jp/2024/"
      }}>{`https://jsconf.jp/2024/`}</a></p>
    <h1>{`スポンサーワークショップでは React コンポーネント設計について発表します`}</h1>
    <p>{`16:00 からトラック D にて、`}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`クラウド診療支援システム CLINICS`}</a>{` の開発で実践している React コンポーネント設計について、開発体制や医療業務システムの観点を踏まえて紹介します。`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://jsconf.jp/2024/talk/medley/"
        }}>{`徹底解剖！医療業務システムの React コンポーネント設計 - 株式会社メドレー | JSConf JP`}</a></p>
    </blockquote>
    <p>{`【ワークショップタイトル】`}</p>
    <p>{`徹底解剖！医療業務システムの React コンポーネント設計`}</p>
    <p>{`【ワークショップ詳細】`}</p>
    <p>{`医療業務システムの Web フロントエンド開発では、高い安全性の要求に応えつつ、複雑化する医療業務に対して診療効率改善につながる質の高いユーザー体験を提供することが求められます。
加えて、長期利用を見据えた保守性の維持も重要です。
これらの要求に応えるため、私たちは約 4 年間の継続的なアーキテクチャ改善を通して React コンポーネント設計を確立してきました。`}</p>
    <p>{`本セッションでは、メドレーの開発体制の背景を交えながら、クラウド診療支援システム CLINICS の Web フロントエンド開発で実践している React コンポーネント設計を紹介します。
具体的には次の内容を予定しています：`}</p>
    <ul>
      <li parentName="ul">{`コンポーネントレイヤーと分割粒度`}</li>
      <li parentName="ul">{`独立性の高い複合コンポーネント設計とデザインパターン`}</li>
      <li parentName="ul">{`代表的なフロントエンドロジックの分類とそれぞれの実装手法`}</li>
      <li parentName="ul">{`クロスファンクショナル（非職能別）開発チームにおける Storybook 駆動開発の活用`}</li>
      <li parentName="ul">{`開発速度と品質を両立するためのテスト戦略`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a1fe5791e0b61547ecd6030ea9bf6d58/d9199/example.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB5klEQVQoz22Qu2sUQRzH99+xsrDQJoiCRYQQIyoS9bTQIibaiMReCxsRg4pGDPggGBB7G6uACsHHJnfJXaIYL7fPeT93d2Z3ZO/UqPHDwHwZ5vP7ws9LIfJb7SCKN7u9IEwwoRATQhkmNAUoiJIUoARAiAmAaLMbbIWxs7bk3BnrMcaDMCaUEcIIZQBhTChlvM4QRwlIIYIIE1q/9MI4ToD7heecM8YyJriQjIutIOr2wqTujIMoAQgnKYxTgDDpB0go50JUVfVTFlIplWVZLpWmXBDKKROYMISpUloqjQljXEBEhJBFYaTS2/KAqqqkzpnICFOUK8JUL0yDCGAqKdf9o7LcuD/w+lqdbFnqzLS/fL8zO39vbmH22au7j188ePJy5tH87YfPb91/enNmbnX9m3OuLMtteYC1JUT07ZI/dHj84OjZY+cu7x85PXZmat+h43sOjO0eGtm1d/j1m8XBzx1yWeZF+Wmlc/L8lcbEtanpG42J6cmr10fHLx5tXDpyanL4xIXF9x/+08y4YFwIpVOIV1Y3Wu2vn5udd0u+3+wsNzsfl9eaaxt+ax0ionUmpPpLNsZaa6s+zrm8KJTW9aqlyvNc9ddrrdU601kmlTbGVFXluR38HvEPRY2xxtaXqZt+AKqFSk3CeK4qAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "発表予定内容の一部：コンポーネント分割粒度の紹介",
            "title": "発表予定内容の一部：コンポーネント分割粒度の紹介",
            "src": "/static/a1fe5791e0b61547ecd6030ea9bf6d58/d9199/example.png",
            "srcSet": ["/static/a1fe5791e0b61547ecd6030ea9bf6d58/5a46d/example.png 300w", "/static/a1fe5791e0b61547ecd6030ea9bf6d58/0a47e/example.png 600w", "/static/a1fe5791e0b61547ecd6030ea9bf6d58/d9199/example.png 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`発表予定内容の一部：コンポーネント分割粒度の紹介`}</em></p>
    <h1>{`ブースでメドレーのエンジニアとお話しましょう`}</h1>
    <p>{`トラック D がある 2F の Communication Area にブースを出展します。`}</p>
    <p>{`ブースでは、メドレーのプロダクトや使用技術、開発組織について、エンジニアとざっくばらんに交流できる場を設ける予定です。`}</p>
    <p>{`休憩エリアの近くになっていますので、セッションの合間などにぜひお立ち寄りください。`}</p>
    <h1>{`おわりに`}</h1>
    <p>{`最後までお読み頂きありがとうございました。`}</p>
    <p>{`当日のブースやワークショップでみなさまとお会いできることを楽しみにしております！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      